import loggerFactory from '../loggerFactory';
import format from './serviceLogFormatter';
import { TDataType } from 'types/TServiceLog';

const logger = loggerFactory({
  ...(process.env.NODE_ENV === 'development'
    ? {
        options: {
          transport: {
            target: 'pino-pretty',
            options: {
              colorize: true,
            },
          },
        },
      }
    : { destination: process.stderr }),
});

const serviceLogger = {
  info: (message: string, LoggerData?: TDataType) => logger.info(...format(message, LoggerData)),
  warn: (message: string, LoggerData?: TDataType) => logger.warn(...format(message, LoggerData)),
  error: (message: string, LoggerData?: TDataType) => logger.error(...format(message, LoggerData)),
};

export default serviceLogger;
