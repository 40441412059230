import loggerFactory from '../loggerFactory';
import { Logger } from 'pino';
import format from './accessLogFormatter';
import { Request, Response, NextFunction } from 'express';

export const startTime = Symbol('startTime');
export const reqObject = Symbol('reqObject');

const accessLogger: Logger = loggerFactory({
  ...(process.env.NODE_ENV === 'development'
    ? {
        options: {
          transport: {
            target: 'pino-pretty',
            options: {
              colorize: true,
            },
          },
        },
      }
    : {}),
});

const accessLoggerMiddleware = () => {
  function onResFinished() {
    // @ts-expect-error - this is not typed, needed to be fixed
    const res = this;
    res.removeListener('close', onResFinished);
    res.removeListener('error', onResFinished);
    res.removeListener('finish', onResFinished);

    const responseTime = Date.now() - res[startTime];
    const req = res[reqObject];

    accessLogger.info(format({ req, res, responseTime }));
  }

  return function fastAccessLoggerMiddleware(req: Request, res: Response, next: NextFunction) {
    const response = res;
    response.on('close', onResFinished);
    response.on('finish', onResFinished);
    response.on('error', onResFinished);
    req.url = req.originalUrl || req.url;

    response[startTime] = Date.now();
    response[reqObject] = req;

    if (next) {
      next();
    }
  };
};

export default accessLoggerMiddleware;
