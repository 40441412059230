import parseRequest from '../utils/parseRequest';
import { Request, Response } from 'express';

export default function withRequestContext(e: any, req = {} as Request, res = {} as Partial<Response>) {
  const { locals: { loggedInUser = {}, requestData = {} } = {} } = res;

  const parsedRequest = parseRequest(req);

  e.context = {
    uniqueRequestId: requestData.uniqueRequestId,
    sessionId: requestData.sessionId,
    userAgent: requestData.userAgent || parsedRequest.header('user-agent') || undefined,
    requestIp: requestData.ip || parsedRequest.ip || undefined,
    loggedInUserId: loggedInUser.id,
    tenant: requestData.tenantContext?.tenant,
    locale: requestData.tenantContext?.locale,
    method: req.method,
    url: req.originalUrl,
  };

  return e;
}
