import pino, { DestinationStream, LoggerOptions, Logger } from 'pino';
import merge from 'lodash.merge';

const defaultOptions: LoggerOptions = {
  timestamp: () => `,"@timestamp":"${new Date(Date.now()).toISOString()}"`,
  formatters: {
    level: (label) => ({ level: label.toUpperCase() }),
  },
  messageKey: 'message',
  errorKey: 'error',
  base: undefined,
};

const loggerFactory = ({
  options,
  destination,
}: {
  options?: LoggerOptions;
  destination?: DestinationStream;
}): Logger => {
  const mergedOptions: LoggerOptions = merge({}, defaultOptions, options);
  return destination ? pino(mergedOptions, destination) : pino(mergedOptions);
};

export default loggerFactory;
