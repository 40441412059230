import { TDataType, TFomattedServiceLogType } from 'types/TServiceLog';

const parseError = ({ stack, code, details, causedBy }: Partial<TDataType>) => {
  const [errorMessage, ...errorStack] = Array.isArray(stack) ? stack : String(stack).split('\n');
  return {
    message: errorMessage.trim(),
    stack: errorStack.map((line) => line.trim()).join('\n'),
    code,
    details,
    causedBy,
  };
};

const formatData = (data: TDataType): TFomattedServiceLogType => {
  const { requestIp, userAgent, uniqueRequestId, sessionId, loggedInUserId, tenant, locale, ...context } =
    data.context || {};
  const error = data.stack ? parseError(data) : undefined;
  return {
    ...(error
      ? {
          message: error.message,
          error: {
            details: error.details,
            code: error.code,
            stack: error.stack,
            causedBy: error.causedBy,
          },
        }
      : {}),
    requestIp,
    userAgent,
    uniqueRequestId,
    sessionId,
    loggedInUserId,
    tenant,
    locale,
    context,
  };
};

const format = (message: string, data?: TDataType): [TFomattedServiceLogType, string | undefined] => {
  const result = [] as unknown as [TFomattedServiceLogType, string | undefined];
  const messages = [];
  if (message) {
    messages.push(message);
  }
  if (typeof data === 'object' && data) {
    const { message: parsedDataMessage, ...parsedData } = formatData(data);
    // if data exists, it should be a first item in results
    result.push(parsedData);
    if (parsedDataMessage) {
      messages.push(parsedDataMessage);
    }
  }
  if (messages.length) {
    result.push(messages.join(' '));
  }
  return result;
};

export default format;
