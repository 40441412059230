import setCookieParser from 'set-cookie-parser';
import { Response } from 'express';

// These cookies contain PII or other sensitive data and could not be logged
const DO_NOT_LOG_RESPONSE_COOKIES = ['MpSslSecurity'];

const parseResponse = (res = {} as Response) => {
  const { statusCode = '', locals = {} } = res;
  const headers = res.getHeaders ? res.getHeaders() : {};
  return {
    headers,
    statusCode,
    requestData: locals.requestData || {},
    loggedInUser: locals.loggedInUser || {},
    header: (name: string, defaultValue = '') => {
      return typeof headers[name] !== 'undefined' ? headers[name] : defaultValue;
    },
    cookies: () => {
      const cookieHeader = headers['set-cookie'] as string;
      const cookies = setCookieParser(cookieHeader);
      const result = {} as Record<string, string>;

      cookies
        .filter((cookie: setCookieParser.Cookie) => !DO_NOT_LOG_RESPONSE_COOKIES.includes(cookie.name))
        .forEach((cookie: setCookieParser.Cookie) => {
          result[cookie.name] = cookie.value;
        });

      return result;
    },
  };
};

export default parseResponse;
