import '../handlers/unhandledRejection';
import logger, { withRequestContext } from '../serviceLogger';
import { Request, Response, NextFunction } from 'express';
import { TDataType } from 'types/TServiceLog';

const errorLoggerMiddleware = () => {
  return (err: TDataType, req: Request, res: Response, next: NextFunction) => {
    if (err instanceof URIError) {
      logger.warn('middlewareError', withRequestContext(err, req, res));
    } else {
      logger.error('middlewareError', withRequestContext(err, req, res));
    }
    next(err);
  };
};

export default errorLoggerMiddleware;
