import parseRequest from '../utils/parseRequest';
import parseResponse from '../utils/parseResponse';
import { Request, Response } from 'express';
import { TFormatedLog } from 'types/TFormatedLog';

const format = ({ req, res, responseTime }: { req: Request; res: Response; responseTime: number }): TFormatedLog => {
  const request = parseRequest(req);
  const response = parseResponse(res);
  const { tenantContext } = response.requestData;
  return {
    date: new Date().toISOString(),
    loggedInUserId: response.loggedInUser.id,
    method: request.method,
    referrer: request.header('referer'),
    requestIp: request.ip || '127.0.0.1',
    requestTimeMillis: responseTime,
    dispatchTime: responseTime,
    fingerprint: response.requestData.fingerPrint,
    responseCode: response.statusCode,
    // will be moved back once compression is done on the Ops side (traeffik)
    // responseSize: +response.header('content-length') || 0,
    sessionId: response.requestData.sessionId,
    ssl: request.isSSL,
    sslVersion: request.header('x-ebay-ssl-version'),
    uniqueRequestId: response.requestData.uniqueRequestId,
    url: request.url,
    userAgent: request.header('user-agent'),
    xForwardedFor: request.header('x-forwarded-for'),
    tenant: tenantContext && tenantContext.tenant,
    locale: tenantContext && tenantContext.locale,
    deviceInfo: response.requestData.deviceInfo,
    appVersion: response.requestData.appVersion,
    responseHeaders: {
      cookie: response.cookies(),
    },
    isBot: response.requestData.isBot,
    isCloudOrigin: response.requestData.isCloudOrigin,
  };
};

export default format;
