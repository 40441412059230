import logger from '../serviceLogger';
import { TDataType } from 'types/TServiceLog';
/**
 * This handler belongs to a bff-core library that creates node server.
 * As there's no such library, this is temporary a common place for this handler.
 * TODO: move to the "bff-core" once it's created.
 */
process.on('unhandledRejection', (err: TDataType) => {
  logger.error('unhandledRejection', err);
});
