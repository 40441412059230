import { Request } from 'express';
import { TParsedRequest } from '../../types/TParsedRequest';

const parseRequest = (req = {} as Request): TParsedRequest => {
  const { headers = {}, method, protocol, originalUrl } = req;
  const header = (name: string, defaultValue = '' as string) => {
    return typeof headers[name] !== 'undefined' ? headers[name] : defaultValue;
  };
  return {
    header,
    headers,
    method,
    protocol,
    originalUrl,
    url: originalUrl ? `${protocol}://${header('host')}${originalUrl}` : undefined,
    isSSL: header('x-forwarded-proto') === 'https',
    ip: header('x-real-ip'),
  };
};

export default parseRequest;
