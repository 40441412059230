export default function withRequestData(e: any, requestData = {} as any) {
  e.context = {
    uniqueRequestId: requestData.uniqueRequestId,
    sessionId: requestData.sessionId,
    userAgent: requestData.userAgent,
    requestIp: requestData.ip,
    tenant: requestData.tenantContext?.tenant,
    locale: requestData.tenantContext?.locale,
  };

  return e;
}
